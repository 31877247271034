import React from "react"
import { Helmet } from "react-helmet"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import Header from "../../components/header"
import Footer from "../../components/footer"
import "../../styles/style.css"
import Location from "../../components/services/location_consultoria"
import { CgArrowLongRight } from "react-icons/cg"
import Icon1 from "../../../static/images/icons/web/icon4.svg"
import Icon2 from "../../../static/images/icons/web/icon5.svg"
import Icon3 from "../../../static/images/icons/web/icon6.svg"
import Icon4 from "../../../static/images/icons/web/icon7.svg"
import Icon5 from "../../../static/images/icons/web/icon8.svg"
import Icon6 from "../../../static/images/icons/web/icon9.svg"
import Icon7 from "../../../static/images/icons/web/icon10.svg"
import Icon8 from "../../../static/images/icons/web/icon11.svg"

const ConsultoriaBarcelona = () => {
  const data = useStaticQuery(graphql`
    query Images_consultoria_barcelona {
      image: allFile(
        filter: { relativeDirectory: { eq: "consultoria" } }
        sort: { fields: name, order: ASC }
      ) {
        nodes {
          id
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `)
  return (
    <div>
      <Helmet>
        {/*Primary Meta Tags*/}
        <title>Consultoría Tecnológica en BARCELONA | Qualoom</title>
        <meta name="title" content="Consultoría Tecnológica en BARCELONA | Qualoom"/>
        <meta name="description" content="¿Buscas una Consultoría Tecnológica en Barcelona? En Qualoom te Asesoramos en el Proceso de Transformación Digital de tu Empresa. ¡Infórmate YA!"/>
        <html lang='es'/>
        <link rel="alternate" href="https://www.qualoom.es/consultoria-tecnologica/barcelona/"/>
        <link rel="canonical" href="https://www.qualoom.es/consultoria-tecnologica/barcelona/"/>
        <meta name="ahrefs-site-verification" content="11d569467b4aa0b0c9daa5b49b9f41eb8a413858d36c9f6ece089482f4d3b630"/>
        
        {/*Open Graph / Facebook*/}
        <meta property="og:locale" content="es_ES"/>
        <meta property="og:type" content="website"/>
        <meta property="og:url" content="https://www.qualoom.es/consultoria-tecnologica/barcelona/"/>
        <meta property="og:title" content="Consultoría Tecnológica en BARCELONA | Qualoom"/>
        <meta property="og:description" content="¿Buscas una Consultoría Tecnológica en Barcelona? En Qualoom te Asesoramos en el Proceso de Transformación Digital de tu Empresa. ¡Infórmate YA!"/>
        <meta property="og:image" content="https://images.ctfassets.net/wy4ivoo9i8ge/2KSu31ogFxZoi4VY54hFVu/24484f8e8b08fc7af28a0696a809d6ff/tw_cards_consultoria.png"/>
        <meta property="og:image:width" content="1200"/>
        <meta property="og:image:height" content="628"/>
        <meta property="fb:admins" content="338114870907726"/>
        
        {/*Twitter*/}
        <meta property="twitter:card" content="summary_large_image"/>
        <meta property="twitter:url" content="https://www.qualoom.es/consultoria-tecnologica/barcelona/"/>
        <meta property="twitter:title" content="Consultoría Tecnológica en BARCELONA | Qualoom"/>
        <meta property="twitter:description" content="¿Buscas una Consultoría Tecnológica en Barcelona? En Qualoom te Asesoramos en el Proceso de Transformación Digital de tu Empresa. ¡Infórmate YA!"/>
        <meta property="twitter:image" content="https://images.ctfassets.net/wy4ivoo9i8ge/2KSu31ogFxZoi4VY54hFVu/24484f8e8b08fc7af28a0696a809d6ff/tw_cards_consultoria.png"/>
        <meta property="twitter:creator" content="https://twitter.com/qualoom"/>

        <script type="application/ld+json">
        {`{"@type": "BreadcrumbsList", "@id":"https://www.qualoom.es/consultoria-tecnologica/barcelona/",              
            "itemListElement":[
              {"@type":"ListItem", "position":"1", "name":"Inicio", "item": {"@id": "https://www.qualoom.es/", "name": "Inicio"}},
              {"@type":"ListItem", "position":"2", "name":"Consultoría tecnológica", "item": {"@id": "https://www.qualoom.es/consultoria-tecnologica/", "name": "Consultoría tecnológica"}},
              {"@type":"ListItem", "position":"3", "name":"Consultoría Tecnológica en BARCELONA", "item": {"@id": "https://www.qualoom.es/consultoria-tecnologica/barcelona/", "name": "Consultoría Tecnológica en BARCELONA"}}
            ]}`}
        </script>
      </Helmet>
      <BackgroundImage fluid={data.image.nodes[0].childImageSharp.fluid}>
        <div className="background-servicios">
          <div className="header_div"><Header /></div>
          <div className="main">
            <div className="empty"></div>
            <div className="text">
              <h1>Consultoría Tecnológica en BARCELONA</h1>
              <p>El área de las tecnologías de la información es nuestro ecosistema nativo. Podemos ayudarte a alcanzar los retos que tu organización establezca y siempre con un trato personalizado. Te invitamos a conocernos, a que nos cuentes tus retos y si no encuentras el servicio en la web que necesitas nos consultes igualmente. Estamos seguros de poder ayudarte.</p>
            </div>
          </div>
        </div>
      </BackgroundImage>
      <div className="services-main">
        <div className="services-main-content">
          <h2>¿Para qué sirve una Consultora Tecnológica?</h2><hr></hr>
          <p>Con Qualoom tendrás un completo asesoramiento en procesos de transaformación digital, consultoría IT, seguridad, DevOps y Cloud e infraestructurate ayudaremos con la optimización, es decir, somos el pilar en el que apoyarte para dar luz a las ideas, retos y objetivos marcados por la organización.</p>
          <a href="/consultoria-tecnologica/" className="hide"><p>Descubre más sobre nuestros servicios de Transformación Digital &nbsp;<CgArrowLongRight/></p></a>
        </div>
      </div>
      <div className="services-main gray">
        <div className="services-main-content">
          <h2>¿Necesitas una consultora tecnológica?</h2><hr></hr>
          <h3>¿En qué te puede ayudar Qualoom?</h3><br/>
          <div className="section-items">
            <div className="section-item-icon"><img src={Icon1} alt="Asesoramiento en procesos de tranformación"></img><p>Te ofrecemos el mejor asesoramiento para los procesos de transfromación IT que quieras hacer en tu organización</p></div>
            <div className="section-item-icon"><img src={Icon2} alt="Optimización de infraestructuras"></img><p>Te ayudamos con la optimización de tus infraestructuras y aplicaciones para obtener los mejores resultados</p></div>
            <div className="section-item-icon"><img src={Icon3} alt="Diseño de arquitecturas"></img><p>Tendrás un diseño personalizado de arquitecturas altamente escalables y en alta disponibilidad, y siempre alineado para alcanzar tus objetivos</p></div>
            <div className="section-item-icon"><img src={Icon4} alt="Pruebas de estrés"></img><p>Someteremos a tus sistemas a un plan riguroso de pruebas de estrés y desvelaremos su tolerancia a fallos para garantizar el mejor comportamiento ante umbrales críticos de exigencia</p></div>
            <div className="section-item-icon"><img src={Icon5} alt="Equipo especializado"></img><p>Tendrás a tu disposición a un equipo especializado en DevOps, SysOps y SysAdmin con más de una década de experiencia en proyectos críticos</p></div>
            <div className="section-item-icon"><img src={Icon6} alt="Recuperación ante desastres"></img><p>Diseñaremos planes de contingencia y continuidad ante desastres personalizados para tu empresa con la seguridad de tener un respaldo en todo momento</p></div>
            <div className="section-item-icon"><img src={Icon7} alt="Auditoría de sistemas"></img><p>Realizaremos una auditoría de tus sistemas de información y de tus sistemas de seguridad para ofrecerte el mejor nivel de exigencia </p></div>
            <div className="section-item-icon"><img src={Icon8} alt="Soporte"></img><p>Contamos con planes de soporte 8x5, 12x5 y 24x7 para que te sientas acompañado en todo momento</p></div>
          </div>
        </div>
      </div>
      <div className="services-main">
        <div className="services-main-content">
          <h2>Una consultoría tecnológica personalizada</h2><hr></hr>
          <h3>¿Dónde ofrecemos nuestros servicios?</h3>
          <Location/>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default ConsultoriaBarcelona